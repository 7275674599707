import React, { useState, useRef } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES } from "@contentful/rich-text-types"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none", color: "var(--green)" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const FAQComponent = props => {
  const content = useRef(null)
  const [height, setHeight] = useState(0)

  const toggleAccordion = () => {
    setHeight(height === 0 ? content.current.scrollHeight : 0)
  }

  return (
    <div>
      {props.question && props.answer && (
        <div>
          <div
            onClick={toggleAccordion}
            className="post-item"
            style={{ cursor: "pointer", marginBottom: "1rem" }}
          >
            <h2 style={{ fontSize: "1.1rem", fontWeight: "500" }}>
              {props.question}
            </h2>
          </div>
          <div
            style={{
              maxHeight: `${height}px`,
              overflow: "hidden",
              transition: "max-height 0.6s ease-in-out",
            }}
            ref={content}
          >
            <div
              style={{
                border: "2px solid #e6e7e8",
                borderTop: "none",
                padding: "1rem",
                marginBottom: "1rem",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <p>{documentToReactComponents(props.answer.json, options)}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FAQComponent
