import React from "react"
import DefaultLayout from "../layouts/defaultLayout"
import SEO from "../components/seo"
import FAQComponent from "../components/faqComponent"

import { useStaticQuery, graphql } from "gatsby"

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(sort: { fields: orderTag }) {
        nodes {
          question
          answer {
            json
          }
        }
      }
    }
  `)
  return (
    <DefaultLayout>
      <SEO title="FAQ" />
      <h1
        style={{
          fontSize: "2rem",
          fontWeight: "500",
          marginBottom: "2rem",
          textAlign: "center",
        }}
      >
        Frequently Asked Questions
      </h1>
      {data.allContentfulFaq.nodes.map(item => {
        return <FAQComponent question={item.question} answer={item.answer} />
      })}
    </DefaultLayout>
  )
}

export default FAQ
